import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import AIPoweredPrecision from "../images/AI-poweredprecision.png"
import TeamofExperts from "../images/TeamofExperts.png"
import WordsThatWork from "../images/WordsThatWork.png"
import Shapefour from "../images/bannerElement/white-design-element-copywriting.png"
import Shapeone from "../images/bannerElement/white-design-element-one.svg"
import Shapethree from "../images/bannerElement/white-design-element-three.svg"
import Shapetwo from "../images/bannerElement/white-design-element-two.svg"
import BlogPostsArticles from "../images/icons/CopyWriting/Blog-posts-articles.svg"
import EmailMarketingCampaigns from "../images/icons/CopyWriting/Email-marketing-campaigns.svg"
import LandingPage from "../images/icons/CopyWriting/Landing-page.svg"
import SocialMediaContent from "../images/icons/CopyWriting/Social-media-content.svg"
import WebsiteCopywriting from "../images/icons/CopyWriting/Website-copywriting.svg"
import "../styles/404.css"

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader"></div>
    </div>
  )
}
const CopyWriting = () => {
  useEffect(() => {
    const items = document.querySelectorAll(".accordion button")

    function toggleAccordion() {
      const itemToggle = this.getAttribute("aria-expanded")

      if (itemToggle === "false") {
        for (let i = 0; i < items.length; i++) {
          items[i].setAttribute("aria-expanded", "false")
        }
        this.setAttribute("aria-expanded", "true")
      } else {
        // Check if the clicked accordion is already open, if yes, do nothing
        if (itemToggle === "true") {
          return
        }
        this.setAttribute("aria-expanded", "false")
      }
    }

    items.forEach(item => item.addEventListener("click", toggleAccordion))

    return () => {
      items.forEach(item => item.removeEventListener("click", toggleAccordion))
    }
  }, [])
  const [imageSrc, setImageSrc] = useState(WordsThatWork)
  const [isLoading, setIsLoading] = useState(false)

  const changeImage = newImageSrc => {
    setIsLoading(true)
    setTimeout(() => {
      setImageSrc(newImageSrc)
      setIsLoading(false)
    }, 50)
  }

  useEffect(() => {
    setIsLoading(true)
    const image = new Image()
    image.src = imageSrc
    image.onload = () => {
      setIsLoading(false)
    }
  }, [imageSrc])

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Copy Writing | Position²</title>
        <script src="https://use.fortawesome.com/23bb216b.js"></script>
        <body className="home" />
      </Helmet>
      <Layout>
        <div class="copy-writing content-creative">
          <section id="Banner">
            <div class="container ">
              <div class="banner-holder">
                <div class="images">
                  <div className="img-one image-shape">
                    <img src={Shapeone} alt="Image" />
                  </div>
                  <div className="img-two image-shape">
                    <img src={Shapetwo} alt="Image" />
                  </div>
                  <div className="img-three image-shape">
                    <img src={Shapethree} alt="Image" />
                  </div>
                  <div className="img-four image-shape">
                    <img src={Shapefour} alt="Image" />
                  </div>
                </div>
                <div class="title">
                  <div class="banner-title">
                    <h1>Craft your brand story, grow your impact</h1>
                    <p>
                      Create experiences that convert - don't just tell your
                      story.
                    </p>
                    <a className="button" href="/contact-us">
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="PageIntro">
            <div class="container">
              <h2>Your content powerhouse awaits</h2>
              <p>
                We don't just write copy; we weave magic with words. Our unique
                blend of AI and human expertise creates content that's both
                strategic and captivating, leaving your audience wanting more.
                We're a passionate bunch of wordsmiths who believe in the power
                of storytelling to transform brands.
              </p>
            </div>
          </section>
          <section id="TextImageSection">
            <div class="container">
              <div class="text-image-holder">
                <div class="text-wrap">
                  <h2>Here’s what sets us apart</h2>
                  <div class="accordion">
                    <div class="accordion-item">
                      <button
                        id="accordion-button-1"
                        aria-expanded="true"
                        onClick={() => changeImage(WordsThatWork)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">Words that Work</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We don't just write; we craft stories that resonate
                          with your audience, driving engagement, conversions,
                          and growth.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-2"
                        aria-expanded="false"
                        onClick={() => changeImage(AIPoweredPrecision)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">
                          AI-powered Precision
                        </span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          We use cutting-edge AI technology to understand your
                          target audience, analyze trends, and deliver content
                          that's laser-focused on results.
                        </p>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <button
                        id="accordion-button-3"
                        aria-expanded="false"
                        onClick={() => changeImage(TeamofExperts)}
                      >
                        <i class="ptwo ptwo-arrow"></i>
                        <span class="accordion-title">A Team of Experts</span>
                      </button>
                      <div class="accordion-content">
                        <p>
                          Our experienced copywriters and strategists work
                          closely with you to understand your brand and goals,
                          crafting content that's tailored to your unique needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <p>
                    The result? We deliver content that's both data-driven and
                    emotionally engaging.
                  </p>
                </div>
                <div class="img-wrap">
                  {isLoading && <Loader />}
                  <div
                    className={`image-container ${isLoading ? "fade-in" : ""}`}
                  >
                    <img
                      alt="Copy write"
                      width="500"
                      height="500"
                      src={imageSrc}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="WeDeliver">
            <div class="container">
              <h2>From start-ups to global brands, we deliver</h2>
              <p>
                Services tailored to different stages of your brand journey,
                from launch to global expansion, to help you achieve your
                copywriting goals.
              </p>
              <div class="horizontalImageIconSec">
                <div class="moduleWrap">
                  <div class="eachModule">
                    <div class="icons">
                      <img src={WebsiteCopywriting} width={100} height={100} />
                    </div>
                    <div class="contents">
                      <h3>Website Copywriting</h3>
                      <p>
                        Captivate visitors and boost conversions with compelling
                        website content.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img
                        src={EmailMarketingCampaigns}
                        width={100}
                        height={100}
                      />
                    </div>
                    <div class="contents">
                      <h3>Email Marketing Campaigns</h3>
                      <p>
                        Craft engaging email sequences that nurture leads and
                        drive sales.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img src={BlogPostsArticles} width={100} height={100} />
                    </div>
                    <div class="contents">
                      <h3>Blog Posts and Articles</h3>
                      <p>
                        Establish thought leadership and engage audience with
                        content that is informative and SEO-optimized.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img src={SocialMediaContent} width={100} height={100} />
                    </div>
                    <div class="contents">
                      <h3>Social Media Content</h3>
                      <p>
                        Create scroll-stopping social media posts that build
                        brand awareness and engagement.
                      </p>
                    </div>
                  </div>
                  <div class="eachModule">
                    <div class="icons">
                      <img src={LandingPage} width={100} height={100} />
                    </div>
                    <div class="contents">
                      <h3>Landing Page Copy</h3>
                      <p>
                        Turn clicks into conversions with persuasive and
                        targeted landing page copy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="contactus-section">
            <div class="container">
              <h2>Ready to transform your brand story?</h2>
              <a className="button" href="/contact-us">
                Get in touch
              </a>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}

CopyWriting.propTypes = {
  siteTitle: PropTypes.string,
}

CopyWriting.defaultProps = {
  siteTitle: ``,
}

export default CopyWriting
